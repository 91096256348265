import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 12 - Changes To Terms Of Service
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              You can review the most current version of the Terms of Service at any time at this page.
            </Text>
            <Text mt={3}>
              We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service 
              by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. 
              Your continued use of or access to our website or the Service following the posting of any changes to these 
              Terms of Service constitutes acceptance of those changes.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
