import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
                Section 2 - General Conditions
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              We reserve the right to refuse service to anyone for any reason at any time.
            </Text>
            <Text mt={3}>
              You understand that your content (not including credit card information), may be transferred unencrypted 
              and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical 
              requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
            </Text>
            <Text mt={3}>
              You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, 
              use of the Service, or access to the Service or any contact on the website through which the service is 
              provided, without express written permission by us.
            </Text>
            <Text mt={3}>
              The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
