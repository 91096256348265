import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 11 - Governing Law
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              These Terms of Service and any separate agreements whereby we provide you Services shall be 
              governed by and construed in accordance with the laws of King County, Washington, United States.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
