import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
                Section 4 - Errors, Inaccuracies And Omissions
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              Occasionally there may be information on our site or in the Service that contains typographical errors, 
              inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product 
              shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies 
              or omissions, and to change or update information or cancel orders if any information in the Service or on 
              any related website is inaccurate at any time without prior notice (including after you have submitted your order).
            </Text>
            <Text mt={3}>
              We undertake no obligation to update, amend or clarify information in the Service or on any related website, 
              including without limitation, pricing information, except as required by law. No specified update or refresh 
              date applied in the Service or on any related website, should be taken to indicate that all information in the 
              Service or on any related website has been modified or updated.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
