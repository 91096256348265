import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 7 - Indemnification
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              You agree to indemnify, defend and hold harmless Sharptree and our parent, subsidiaries, affiliates, partners, 
              officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, 
              harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or 
              arising out of your breach of these Terms of Service or the documents they incorporate by reference, 
              or your violation of any law or the rights of a third-party.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
