import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import Intro from "../sections/terms-of-service/Intro";
import Section1 from "../sections/terms-of-service/Section1";
import Section2 from "../sections/terms-of-service/Section2";
import Section3 from "../sections/terms-of-service/Section3";
import Section4 from "../sections/terms-of-service/Section4";
import Section5 from "../sections/terms-of-service/Section5";
import Section6 from "../sections/terms-of-service/Section6";
import Section7 from "../sections/terms-of-service/Section7";
import Section8 from "../sections/terms-of-service/Section8";
import Section9 from "../sections/terms-of-service/Section9";
import Section10 from "../sections/terms-of-service/Section10";
import Section11 from "../sections/terms-of-service/Section11";
import Section12 from "../sections/terms-of-service/Section12";
import Section13 from "../sections/terms-of-service/Section13";

const Privacy = (props) => {
  return (
    <>
      <PageWrapper>
        <Seo 
          title="Sharptree | Terms of Service | Mobile Reimagined"
          description="See the legal terms that apply to anyone who visits our website or uses our services. These terms are necessary in order to protect you and us, and to make our services possible and more enjoyable."
          pathname={props.location.pathname} 
        />
        <PageHero title="Terms of Service" />
        <Intro />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 />
        <Section13 />
      </PageWrapper>
    </>
  );
};
export default Privacy;
