import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 1 - Online Customer Console Terms
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              By agreeing to these Terms of Service, you represent that you are at least the age of majority in your
              state or province of residence, or that you are the age of majority in your state or province of residence
              and you have given us your consent to allow any of your minor dependents to use this site.
            </Text>
            <Text mt={3}>
              You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service,
              violate any laws in your jurisdiction (including but not limited to copyright laws).
            </Text>
            <Text mt={3}>
              You must not transmit any viruses or any code of a destructive nature.
            </Text>
            <Text mt={3}>
              A breach or violation of any of the Terms will result in an immediate termination of your Services.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
