import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 8 - Severability
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              In the event that any provision of these Terms of Service is determined to be unlawful, 
              void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent 
              permitted by applicable law, and the unenforceable portion shall be deemed to be severed from 
              these Terms of Service, such determination shall not affect the validity and enforceability 
              of any other remaining provisions.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
