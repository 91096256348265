import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Intro = () => (
  <>
    <Section className="pb-5">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col>
            <Title variant="quote" className="mb-2">
              Terms of Service
            </Title>
            <Text variant="small">
              Last updated: May 6, 2021
            </Text>
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Overview
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              This website is operated by Sharptree. Throughout the site, the terms "we", "us" and "our" refer to Sharptree.
              Sharptree offers this website, including all information, tools and services available from this site to you,
              the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </Text>
            <Text mt={3}>
              By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound 
              by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and 
              conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all 
              users of the site, including without limitation users who are browsers, vendors, customers, merchants, 
              and/ or contributors of content.
            </Text>
            <Text mt={3}>
              Please read these Terms of Service carefully before accessing or using our website. 
              By accessing or using any part of the site, you agree to be bound by these Terms of Service. 
              If you do not agree to all the terms and conditions of this agreement, then you may not access 
              the website or use any services. If these Terms of Service are considered an offer, acceptance 
              is expressly limited to these Terms of Service.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Intro;
