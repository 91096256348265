import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 10 - Entire Agreement
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              The failure of us to exercise or enforce any right or provision of these Terms of Service shall 
              not constitute a waiver of such right or provision.
            </Text>
            <Text mt={3}>
              These Terms of Service and any policies or operating rules posted by us on this site or in respect 
              to the Service constitutes the entire agreement and understanding between you and us and govern 
              your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, 
              whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
            </Text>
            <Text mt={3}>
              Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
