import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 6 - Disclaimer Of Warranties; Limitation Of Liability
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
            </Text>
            <Text mt={3}>
              We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
            </Text>
            <Text mt={3}>
              You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
            </Text>
            <Text mt={3}>
              You expressly agree that your use of, or inability to use, the service is at your sole risk. 
              The service and all products and services delivered to you through the service are (except as expressly stated by us) 
              provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, 
              either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, 
              fitness for a particular purpose, durability, title, and non-infringement.
            </Text>
            <Text mt={3}>
              In no case shall Sharptree, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, 
              service providers, resellers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, 
              punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, 
              lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), 
              strict liability or otherwise, arising from your use of any of the service or any products procured using the service, 
              or for any other claim related in any way to your use of the service or any product, including, but not limited to, 
              any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the 
              service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised
              of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability 
              for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the 
              maximum extent permitted by law.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
