import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Section, Text, Title } from "../../components/Core";
import { TermsListAlphe } from "./Common";

const Section1 = () => (
  <>
    <Section className="pt-0 pb-5">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col>
            <Title variant="quote" className="mb-2">
              Section 5 - Prohibited Uses
            </Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Text>
              In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
            </Text>
            <TermsListAlphe>
              <li>for any unlawful purpose;</li>
              <li>to solicit others to perform or participate in any unlawful acts;</li>
              <li>to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
              <li>to infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
              <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation,
              religion, ethnicity, race, age, national origin, or disability;</li>
              <li>to submit false or misleading information;</li>
              <li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect
              the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
              <li>to collect or track the personal information of others;</li>
              <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
              <li>for any obscene or immoral purpose; or</li>
              <li>to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</li>
            </TermsListAlphe>
            <Text mt={3}>
              We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Section1;
